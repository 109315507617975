.wrapper {
    width: 100%;
    height: 60px;
    background-color: #2fa1b3;
}

.inner {
    width: 75%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    ul {
        display: flex;
        align-items: center;
    }
    li {
        list-style: none;
        color: #fff;
        font-weight: 700;
        height: 10px;
        padding-left: 10px;
        font-size: 13px;
    }
}

.btn-bars {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

.form-filter {
    width: 100%;
    height: 500px;
    background-color: #ccc;
    position: absolute;
}

#link {
    color: #fff;
    text-decoration: none;
}

#link:hover {
    color: #ccc;
    transition: 0.3s;
}

.dropdown {
    width: 250px;
    height: 30px;
    position: absolute;
    top: 200%;
    right: -90px;
    cursor: pointer;
}
