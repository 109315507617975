.wrapper {
    width: 60%;
    margin: 10px auto;
}

#search-province {
    width: 50%;
    border: 1px solid #ccc;
    height: 40px;
    padding-left: 10px;
    outline: none;
    font-weight: 600;
}

.no-job {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 50%;
    }
}

.form-card {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid #2fa1b3;
}

.column-left {
    width: 75px;
    height: 75px;
    img {
        width: 100%;
        height: 100%;
        background-size: cover;
    }
    img:hover {
        transform: scale(1.05);
        transition: 0.3s;
    }
}

.column-right {
    img {
        width: 50px;
    }
    h3 {
        font-size: 20px;
        font-weight: 700;
    }
}
.column-right:hover {
    color: #2fa1b3;
    transition: 0.2s;
}
