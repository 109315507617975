.form-card {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid #2fa1b3;
}

.column-left {
    width: 150px;
    display: flex;
    justify-content: center;
    img {
        width: 100%;
        height: 100%;
    }
    img:hover {
        transform: scale(1.05);
        transition: 0.3s;
    }
}

.column-right {
    img {
        width: 50px;
    }
    h3 {
        font-size: 20px;
        font-weight: 700;
    }
}
.column-right:hover {
    color: #2fa1b3;
    transition: 0.2s;
}
