.wrapper {
    width: 80%;
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0px auto;
}

.logo {
    width: 10%;
    img {
        width: 125px;
        height: 55px;
    }
}

.search {
    width: 450px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    height: 40px;
    input {
        width: 95%;
        height: 50%;
        border: none;
        outline: none;
        padding-left: 10px;
        font-weight: 700;
    }
}
